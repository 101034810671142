<template>
  <div class="container">
    <div class="row justify-content-center align-items-center">
    <div class="col-6 mx-auto">
      <div class="row error-message" v-if="this.resetPasswordError">
        Passwort konnte nicht zurückgesetzt werden. Stellen Sie sicher, dass die eingegebenen Passwörter übereinstimmen. 
        Das neue Passwort muss mindestens 6 Zeichen lang sein.
      </div>
      <div class="row error-message" v-if="this.resetPasswordErrorNoCode">
        Passwort konnte nicht zurückgesetzt werden. Stellen Sie sicher, dass Sie den Link in der Email zum Zurücksetzen des Passworts verwendet haben.
      </div>
      <div class="row small password-check" :class="{ 'green-text': this.newPassword.length >= 6 }"><span><i class="bi bi-check-circle-fill"></i> Passwort muss mindestens 6 Zeichen lang sein</span></div>
      <div class="row small password-check" :class="{ 'green-text': this.newPassword === this.confirmNewPassword && this.confirmNewPassword.length  && this.newPassword }"><span><i class="bi bi-check-circle-fill"></i> Passwörter stimmen überein</span></div>
      <form @submit="resetPassword" style="padding-top: 2em">
        <div class="row">
          <div class="">
            <label for="inputPasswordLogin" class="form-label"
              >Neues Passwort</label
            >
            <input
              type="password"
              v-model="newPassword"
              class="form-control custom-input"
              id="inputPasswordLogin"
            />
            <label for="inputPasswordLoginConfirm" class="form-label"
              >Neues Passwort bestätigen</label
            >
            <input
              type="password"
              v-model="confirmNewPassword"
              class="form-control custom-input"
              id="inputPasswordLoginConfirm"
            />
          </div>
        </div>
        <div class="view-controls">
          <button
            type="submit"
            class="btn custom-btn"
            :disabled="
              newPassword.length < 6 ||
              confirmNewPassword.length < 6 ||
              newPassword !== confirmNewPassword
            "
          >
            Passwort ändern
          </button>
        </div>
      </form>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newPassword: "",
      confirmNewPassword: "",
      done: false,
      resetPasswordError: false,
      resetPasswordErrorNoCode: false
    };
  },
  methods: {
    async resetPassword(e) {
      e.preventDefault();
      if(this.newPassword.length < 6 || this.confirmNewPassword.length < 6 || this.newPassword !== this.confirmNewPassword){
        this.resetPasswordError = true;
        this.newPassword = "";
        this.confirmNewPassword = "";
        return;
      }
      if(this.$route.query.code == null){
        this.resetPasswordErrorNoCode = true;
        this.newPassword = "";
        this.confirmNewPassword = "";
        return;
      }
      try {
        this.axios
          .post(
            process.env.VUE_APP_STRAPI_API_URL + `/api/auth/reset-password`,
            {
              code: this.$route.query.code,
              password: this.newPassword,
              passwordConfirmation: this.confirmNewPassword,
            }
          )
          .then(() => {
            this.done = true;
            this.$router.push("/");
          });
      } catch (err) {
        console.log(err);
        this.resetPasswordError = true;
        this.newPassword = "";
        this.confirmNewPassword = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-view {
  width: 100%;
  height: 100%;
}

.view-controls {
  padding-top: 20px;
  float: right;
}

.form-label {
  font-size: 22px;
  color: #006699;
  font-family: "PT Sans", sans-serif;
}
.password-check{
  color: rgb(81, 81, 81);
}
.green-text{
  color: green;
}
</style>
